<template>
    <div> 
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <h5 class="m-t-10">
                            Data Pembiayaan Peserta
                        </h5>
                        <div class="float-right" style="margin-top:-65px">
                            <a href="javascript:void(0)" v-on:click="getData()" class="btn btn-primary rounded mr-2">Muat Ulang</a>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Status</label>
                                    <select class="form-control" v-model="daftar_ulang" v-on:change="getByKeywords()">
                                        <option value="">Filter Status</option>
                                        <option value="1">Sudah Daftar Ulang</option>
                                        <option value="0">Belum Daftar Ulang</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-8">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Kata kunci</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getByKeywords">
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive">
                                    <div v-if="dataList.length > 0" >
                                        <table class="table table-bordered" style="font-size:13px">
                                            <thead>
                                                <tr>
                                                    <th style="font-size:13px !important;width:3%" class="text-center">No</th>
                                                    <th style="font-size:13px !important;width:20%">Nama Peserta</th>
                                                    <th style="font-size:13px !important;width:20%">Nomor Pendaftaran</th>
                                                    <th style="font-size:13px !important;width:10%; text-align:center">Status Daftar Ulang</th>
                                                    <th style="font-size:13px !important;width:5%; text-align:center">Status Kelulusan</th>
                                                    <th style="font-size:13px !important;width:10%" class="text-center">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>
                                                        {{ item.fullname }}
                                                    </td>
                                                    <td>
                                                        {{ item.no_pendaftaran }}
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.daftar_ulang == 1" class="badge badge-success">Sudah Daftar Ulang</span>
                                                        <span v-if="item.daftar_ulang == 0" class="badge badge-dark">Belum Daftar Ulang</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.lulus == 1" class="badge badge-success">Sudah dinyatakan Lulus</span>
                                                        <span v-if="item.lulus == 0" class="badge badge-danger">Belum dinyatakan Lulus</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <button data-toggle="modal" data-target="#modal_form_daftar_ulang" type="button" class="btn btn-outline-primary rounded btn-sm" v-on:click="viewTagihan(item.users_existing_id, item.ppdb_formulir_id, item.fullname, item.no_pendaftaran)">Lihat Tagihan</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>


                                <nav v-if="(!dataFetch && dataList.length > 0)" aria-label="navigation" class="m-t-20">
                                    <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                </nav>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modal_form_daftar_ulang" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-lg modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> Lihat Tagihan</div>
                            <div>
                                <button id="btnCloseModalStatus" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>                    

                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Nama Peserta</div>
                            <div class="font-weight-600">{{ fullname }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Nomor Pendaftaran</div>
                            <div class="font-weight-600">{{ no_pendaftaran }}</div>
                        </div>

                        <div v-if="fetch_modal" class="text-center mb-5">Loading...</div>

                        <div v-if="(!fetch_modal && data_modal.data_tagihan.length > 0)" class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <td>Nama Tagihan</td>
                                        <td style="width: 20%;" class="text-center">Jumlah Tagihan</td>
                                        <td style="width: 5%;" class="text-center">Status</td>
                                        <td style="width: 5%;" class="text-center">Lunas</td>
                                        <td style="width: 5%;" class="text-center">Belum Lunas</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(itemModal, indexModal) in data_modal.data_tagihan" :key="indexModal">
                                        <td>{{ itemModal.nama_tagihan }}</td>
                                        <td style="text-align: right;">{{ itemModal.text_jumlah_tagihan }}</td>
                                        <td class="text-center">
                                            <span v-if="itemModal.lunas == 1" class="badge badge-success">Lunas</span>
                                            <span v-if="itemModal.lunas == 0" class="badge badge-danger">Belum Lunas</span>
                                        </td>
                                        <td style="text-align: center;">
                                            <input type="checkbox" style="transform: scale(2);" 
                                                :checked="form.status_pembayaran[itemModal.id] === 'lunas'"
                                                @change="toggleCheckbox(itemModal.id, 'lunas')">
                                        </td>
                                        <td style="text-align: center;">
                                            <input type="checkbox" style="transform: scale(2);" 
                                                :checked="form.status_pembayaran[itemModal.id] === 'belum_lunas'"
                                                @change="toggleCheckbox(itemModal.id, 'belum_lunas')">
                                        </td>                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div v-if="(!fetch_modal && data_modal.data_tagihan.length > 0)" class="d-flex align-items-center pb-3 row mt-3">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Catatan</label>
                                    <textarea class="form-control" rows="4" placeholder="Masukan catatan (optional)..." v-model="form.catatan"></textarea>
                                </div>
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Pilih Status Daftar Ulang</label>
                                    <select class="form-control" v-model="form.daftar_ulang">
                                        <option value="1">Sudah Daftar Ulang</option>
                                        <option value="0">Belum Daftar Ulang</option>
                                    </select>
                                </div>
                                <label for="lulus" v-if="form.daftar_ulang == 1">
                                <input type="checkbox" name="lulus" id="lulus" v-model="form.lulus" true-value="1" false-value="0" />
                                    Dengan mencentang kotak ini, calon siswa akan dinyatakan lulus dan resmi terdaftar sebagai siswa di sekolah ini.
                                </label>
                                <hr/>
                                <button v-on:click="saveData()" type="button" class="btn btn-primary btn-block"><i class="fas fa-save"></i> Simpan Data</button>
                            </div>                        
                        </div>
                    </div>

                </div> 
            </div>
        </div>
    </div>        
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'PpdbPembiayaan',
        metaInfo: {
            title: 'Pembiayaan Peserta',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                waiting         : false,
                emptyState      : false,
                dataFetch       : false,
                fetch_modal       : false,
                dataList        : [],
                daftar_ulang    : "",
                keywords        : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 50,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                fullname        : '',
                no_pendaftaran  : '',
                ppdb_formulir_id  : '',
                data_modal      : {
                    data_tagihan : []
                },
                form : {
                    daftar_ulang : 0,
                    status_pembayaran : {},
                    users_id : '',
                    lulus : 0,
                    catatan : ''
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            toggleCheckbox(index, value) {
                if (this.form.status_pembayaran[index] === value) {
                    this.$set(this.form.status_pembayaran, index, null);
                } else {
                    this.$set(this.form.status_pembayaran, index, value);
                }
            },
            getByKeywords: function() {
                this.pagination.currentPage = 1;
                this.getData();
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/ppdb-formulir/list-pembiayaan';
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords        : this.keywords,
                        daftar_ulang    : this.daftar_ulang,
                        page            : this.pagination.currentPage,
                        limit           : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            viewTagihan: function(users_id, ppdb_formulir_id, fullname, no_pendaftaran) {
                this.fullname           = fullname;
                this.no_pendaftaran     = no_pendaftaran;
                this.ppdb_formulir_id   = ppdb_formulir_id;
                this.form.users_id      = users_id;
                this.fetch_modal        = true;
                let uri                 = process.env.VUE_APP_APIHOST+'admin/ppdb-formulir/list-pembiayaan-detail';
                this.$http.get(uri,{
                    params : {
                        users_id        : users_id,
                        id    : ppdb_formulir_id,
                    }
                }).then(res => {
                    this.fetch_modal = false;
                    this.data_modal = res.data.data;
                    this.form.daftar_ulang   = this.data_modal.daftar_ulang;
                    this.form.catatan   = this.data_modal.catatan;
                    this.form.lulus   = this.data_modal.lulus;

                    this.data_modal.data_tagihan.forEach(element => {
                        let lunas = "belum_lunas"
                        if(element.lunas == '1') {
                            lunas = "lunas"
                        }
                        this.toggleCheckbox(element.id, lunas);
                    });
                });
            },
            saveData: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Konfirmasi',
                    html: 'Apakah kamu yakin menyimpan data?',
                    confirmButtonText: 'Iya, Simpan',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        let formData = new FormData();
                        formData.append('ppdb_formulir_id', this.ppdb_formulir_id);
                        formData.append('users_id', this.form.users_id);
                        formData.append('daftar_ulang', this.form.daftar_ulang);
                        formData.append('catatan', this.form.catatan);
                        formData.append('lulus', this.form.lulus);
                        formData.append('status_pembayaran', JSON.stringify(this.form.status_pembayaran)); // Konversi ke JSON

                        this.$http.post(process.env.VUE_APP_APIHOST + 'admin/ppdb-pembiayaan/save', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.form.status_pembayaran = {};
                                        document.getElementById("btnCloseModalStatus").click();
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                        .catch(() => {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: 'Please check your internet connection.',
                            });
                            this.waiting = false;
                        });
                    }
                });
            }
        }
    }
</script>
