<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">{{label}} Sekolah</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'school'}">Sekolah</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Form</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header">
            <div class="page-block" v-if="dataFetch">
                <p>Sedang mengambil data...</p>
            </div>
            <div class="page-block" v-if="!dataFetch">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <input type="hidden" name="id" v-model="form.id">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Kode</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.code" :class="{ 'is-invalid': submitted && $v.form.code.$error }">
                                    <div v-if="submitted && !$v.form.code.required" class="invalid-feedback">Judul wajib diisi!</div>    
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Nama</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.name" :class="{ 'is-invalid': submitted && $v.form.name.$error }">
                                    <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">Judul wajib diisi!</div>    
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Nama Kepala Sekolah</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.headmaster_name">
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">NIP Kepala Sekolah</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.headmaster_number">
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Nama Koordinator Guru BK</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.teacher_name">
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">NIP Koordinator Guru BK</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.teacher_number">
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Kelompok Bahasa</label>
                                    <select class="form-control" v-model="form.group_bahasa">
                                        <option value="0">Tidak Aktif</option>
                                        <option value="1">Aktif</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Kelompok Agama</label>
                                    <select class="form-control" v-model="form.group_agama">
                                        <option value="0">Tidak Aktif</option>
                                        <option value="1">Aktif</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1" >Daftar Kelas</label>
                                    <textarea  class="form-control" rows="3" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.list_class"></textarea>
                                    <small><i>Jika lebih dari 1 kelas, silahkan isi dengan menggunakan tanda "," (Koma). contoh: (Kelas 10 IPA, Kelas 10 IPS, Kelas 11 IPS, Kelas 11 IPA)</i></small>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Kolom minat (Psikotest)</label>
                                    <select class="form-control" v-model="form.passion">
                                        <option value="0">Tidak Aktif</option>
                                        <option value="1">Aktif</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Kolom bakat (Psikotest)</label>
                                    <select class="form-control" v-model="form.talent">
                                        <option value="0">Tidak Aktif</option>
                                        <option value="1">Aktif</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Aktifkan Fitur DISC (PSIKOTEST)</label>
                                    <select class="form-control" v-model="form.activate_disc">
                                        <option value="0">Tidak Aktif</option>
                                        <option value="1">Aktif</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Aktifkan Fitur IST (PSIKOTEST)</label>
                                    <select class="form-control" v-model="form.activate_ist">
                                        <option value="0">Tidak Aktif</option>
                                        <option value="1">Aktif</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Rumus DISC Sekolah</label>
                                    <select class="form-control" v-model="form.activate_disc_category">
                                        <option value="0">Tidak Aktif</option>
                                        <option value="1">Aktif</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Aktifkan Unduh Hasil Tes Assesment (AKM)</label>
                                    <select class="form-control" v-model="form.activate_download_result_akm">
                                        <option value="0">Tidak Aktif</option>
                                        <option value="1">Aktif</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Aktifkan Rencana Pilihan Jurusan Setelah Lulus (PSIKOTEST)</label>
                                    <select class="form-control" v-model="form.activate_department_plan">
                                        <option value="0">Tidak Aktif</option>
                                        <option value="1">Aktif</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Kode Login CBT</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.url_login">
                                    <small>Url Login : https://app.griyabelajar.com/#/login-cbt/{{ form.url_login }}</small>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Logo</label>
                                    <input type="file" class="form-control" name="logo" id="logo" @change="previewLogo" accept="logo/png, logo/gif, logo/jpeg">
                                </div>

                                <div class="form-group col-md-6" v-if="preview_logo">
                                    <label for="exampleInputEmail1">Preview Logo</label>
                                    <div class="thumbnail mb-4">
                                        <div class="thumb">
                                            <a :href="preview_logo" data-lightbox="1" target="blank">
                                                <img :src="preview_logo" alt="" class="img-fluid img-thumbnail" style="width:50%">
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Kop Surat (Untuk Kartu Peserta CBT)</label>
                                    <input type="file" class="form-control" name="letterhead" id="letterhead" @change="previewLetterHead" accept="image/png, image/gif, image/jpeg">
                                    <small>Disarankan menggunakan <b>resolusi (1920x474)</b></small>
                                </div>

                                <div class="form-group col-md-6" v-if="preview_letterhead">
                                    <label for="exampleInputEmail1">Preview Kop Surat</label>
                                    <div class="thumbnail mb-4">
                                        <div class="thumb">
                                            <a :href="preview_letterhead" data-lightbox="1" target="blank">
                                                <img :src="preview_letterhead" alt="" class="img-fluid img-thumbnail" style="width:50%">
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Menit Toleransi untuk login</label>
                                    <input type="number" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan dalam itungan menit, misal 1 menit maka diisi 1..." v-model="form.login_tolerance_minutes">
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Acak Soal</label>
                                    <select class="form-control" v-model="form.random_questions">
                                        <option value="0">Tidak Aktif</option>
                                        <option value="1">Aktif</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Default Text CBT</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data" v-model="form.cbt_text">
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Kode Sistem Absensi</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data" v-model="form.attendance_system_code">
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Non Aktifkan Pengaturan Akun</label>
                                    <select class="form-control" v-model="form.disable_profile">
                                        <option value="1">Iya</option>
                                        <option value="0">Tidak</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Kode Sistem PPDB</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data" v-model="form.url_ppdb">
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary m-r-10" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                            <router-link :to="{name : 'school'}" class="btn btn-outline-primary"><i class="fa fa-back"></i> Kembali</router-link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'Sekolah',
        metaInfo: {
            title: 'Form Sekolah',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label   : 'Tambah',
                preview_logo   : '',
                preview_letterhead   : '',
                form : {
                    id          : "",
                    code        : "",
                    name        : "",
                    headmaster_number   : "",
                    headmaster_name     : "",
                    teacher_number  : "",
                    teacher_name    : "",
                    group_bahasa    : 0,
                    group_agama    : 0,
                    list_class      : "",
                    passion : 0,
                    talent : 0,
                    activate_disc_category : 0,
                    activate_disc : 1,
                    activate_ist : 1,
                    activate_download_result_akm : 1,
                    activate_department_plan : 1,
                    logo           : "",
                    letterhead           : "",
                    url_login           : "",
                    login_tolerance_minutes : "",
                    random_questions : 0,
                    cbt_text : "",
                    attendance_system_code : "",
                    url_ppdb : "",
                    disable_profile : 0,
                },
                dataFetch       : false,
                submitted       : false,
                waiting         : false
            }
        },
        validations: {
            form: {
                code: {
                    required
                },
                name: {
                    required
                }
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('code', this.form.code);
                    formData.append('name', this.form.name);
                    formData.append('headmaster_number', this.form.headmaster_number);
                    formData.append('headmaster_name', this.form.headmaster_name);
                    formData.append('teacher_number', this.form.teacher_number);
                    formData.append('teacher_name', this.form.teacher_name);
                    formData.append('group_bahasa', this.form.group_bahasa);
                    formData.append('group_agama', this.form.group_agama);
                    formData.append('list_class', this.form.list_class);
                    formData.append('passion', this.form.passion);
                    formData.append('talent', this.form.talent);
                    formData.append('activate_disc_category', this.form.activate_disc_category);
                    formData.append('activate_disc', this.form.activate_disc);
                    formData.append('activate_ist', this.form.activate_ist);
                    formData.append('activate_download_result_akm', this.form.activate_download_result_akm);
                    formData.append('activate_department_plan', this.form.activate_department_plan);
                    formData.append('logo', this.form.logo);
                    formData.append('letterhead', this.form.letterhead);
                    formData.append('url_login', this.form.url_login);
                    formData.append('login_tolerance_minutes', this.form.login_tolerance_minutes);
                    formData.append('cbt_text', this.form.cbt_text);
                    formData.append('attendance_system_code', this.form.attendance_system_code);
                    formData.append('url_ppdb', this.form.url_ppdb);
                    formData.append('disable_profile', this.form.disable_profile);
                    formData.append('random_questions', this.form.random_questions);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/school/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'school' });
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                html: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.dataFetch = true;
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/school/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;

                            this.form.id                     = getResponse.id;
                            this.form.code                   = getResponse.code;
                            this.form.name                   = getResponse.name;
                            this.form.headmaster_number      = getResponse.headmaster_number;
                            this.form.headmaster_name        = getResponse.headmaster_name;
                            this.form.teacher_number         = getResponse.teacher_number;
                            this.form.teacher_name           = getResponse.teacher_name;
                            this.form.group_bahasa           = getResponse.group_bahasa;
                            this.form.group_agama            = getResponse.group_agama;
                            this.form.list_class             = getResponse.list_class;
                            this.form.passion                = getResponse.passion;
                            this.form.talent                 = getResponse.talent;
                            this.form.activate_disc_category = getResponse.activate_disc_category;
                            this.form.activate_disc          = getResponse.activate_disc;
                            this.form.activate_ist           = getResponse.activate_ist;
                            this.form.activate_download_result_akm  = getResponse.activate_download_result_akm;
                            this.form.activate_department_plan      = getResponse.activate_department_plan;
                            this.form.url_login      = getResponse.url_login;
                            this.form.login_tolerance_minutes      = getResponse.login_tolerance_minutes;
                            this.form.cbt_text      = getResponse.cbt_text;
                            this.form.attendance_system_code      = getResponse.attendance_system_code;
                            this.form.url_ppdb      = getResponse.url_ppdb;
                            this.form.random_questions      = getResponse.random_questions;
                            this.form.disable_profile      = getResponse.disable_profile;
                            this.form.logo     = getResponse.logo;
                            this.preview_logo  = getResponse.logo;
                            this.form.letterhead     = getResponse.letterhead;
                            this.preview_letterhead  = getResponse.letterhead;

                            this.dataFetch = false;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'school' });
                                }
                            });
                        }
                    });
                }
            },
            previewLogo(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_logo = URL.createObjectURL(file);
                    this.form.logo = file;
                }
            },
            previewLetterHead(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_letterhead = URL.createObjectURL(file);
                    this.form.letterhead = file;
                }
            }
        }
    }
</script>