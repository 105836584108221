<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Data Users</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <router-link class="btn btn-primary rounded" :to="{name : 'users_add'}"><i class="fa fa-plus"></i> Tambah Data</router-link>
                                <button v-if="selectedIds.length > 0" type="button" class="btn btn-danger rounded ml-2" v-on:click="deleteData()"><i class="fa fa-trash"></i> Hapus Data</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Asal Sekolah</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="school_id" v-on:change="getByFilter();getClass()">
                                        <option value="">Lihat Semua</option>
                                        <option v-for="(item, index) in dataSchool" :key="index" :value="item.id">{{item.name}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Kelas</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="school_class" v-on:change="getByFilter()">
                                        <option value="">Lihat Semua</option>
                                        <option v-for="(item, index) in dataClass" :key="index" :value="item">{{item}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Role</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="type" v-on:change="getByFilter()">
                                        <option value="">Lihat Semua</option>
                                        <option value="member">Member / Siswa</option>
                                        <option value="proctor">Proktor</option>
                                        <option value="content">Guru</option>
                                        <option value="headmaster">Kepala Sekolah</option>
                                        <option value="ppdb_admin">Admin PPDB (Panitia)</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Kata kunci</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan nama dan email..." v-model="keywords" v-on:keyup.enter="getByFilter()">
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch">
                                    <div v-if="dataList.length > 0" >
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">
                                                            <input type="checkbox" v-model="selectAll" @change="selectAllItems">
                                                        </th>
                                                        <th class="text-center">No</th>
                                                        <th>Nama</th>
                                                        <th>Email</th>
                                                        <th>Role</th>
                                                        <th>Password</th>
                                                        <th>Terakhir Login</th>
                                                        <th style="width:5%">Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="dataList.length > 0">
                                                    <tr v-for="(item, index) in dataList" :key="index">
                                                        <td class="text-center">
                                                            <input type="checkbox" v-model="selectedIds" :value="item.id">
                                                        </td>
                                                        <td class="text-center">
                                                            {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                        </td>
                                                        <td>{{item.fullname}}<br/>
                                                            <small>Asal Sekolah: {{ item.school_name ? item.school_name : '-' }}</small>
                                                            <br v-if="item.type == 'member'"/>
                                                            <small v-if="item.type == 'member'">Kelas: {{ item.school_class ? item.school_class : '-'}}</small>
                                                            <br/>
                                                            <small>Tanggal bergabung: {{ item.created_at }}</small>
                                                            <br v-if="item.account_special == 0"/>
                                                            <small class="badge badge-info" v-if="item.account_special == 0">Akun Umum (Daftar Mandiri)</small>
                                                        </td>
                                                        <td>{{item.email}}</td>
                                                        <td>
                                                            <span v-if="item.type == 'member' " class="badge badge-success">Member</span>
                                                            <span v-if="item.type == 'proctor' " class="badge badge-info">Proktor</span>
                                                            <span v-if="item.type == 'content' " class="badge badge-warning">Guru</span>
                                                            <span v-if="item.type == 'headmaster' " class="badge badge-dark">Kepala Sekolah</span>
                                                            <span v-if="item.type == 'ppdb_admin' " class="badge badge-light">Admin PPDB (Panitia)</span>
                                                        </td>
                                                        <td><a v-on:click="setId(item.id)" data-toggle="modal" data-target="#modalNewPasswordAdmin" href="javascript:void(0)">Ganti Password</a></td>
                                                        <td>{{item.last_login}}</td>
                                                        <td>
                                                            <router-link :to="{name : 'users_edit', params: { id: item.id }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" type="button" class="btn btn-primary btn-sm rounded m-r-10"><i class="fas fa-edit"></i> Ubah</router-link>
                                                            <button v-if="item.status == 'active'" class="btn btn-sm btn-danger rounded" type="button" v-on:click="nonActive(item.id)">Non Aktifkan</button>
                                                            <button v-if="item.status == 'non-active'" class="btn btn-sm btn-success rounded" type="button" v-on:click="nonActive(item.id, 'active')">Aktifkan</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-if="dataList.length < 1">
                                                    <tr colspan="6">
                                                        <td>Data tidak ditemukan</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <nav aria-label="navigation" class="m-t-20">
                                            <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                        </nav>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalNewPasswordAdmin" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> Ganti Password</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    

                    <form v-on:submit.prevent="onSubmit">
                        <div class="d-block px-3 pt-3 pb-0">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="mt-1">
                                        <label>Password Baru</label>
                                        <input type="password" autocomplete="off" class="form-control" aria-describedby="emailHelp" placeholder="Masukan Password Baru..." v-model="form.new_password"
                                        :class="{ 'is-invalid': submitted && $v.form.new_password.$error }"
                                        >

                                        <div v-if="submitted && !$v.form.new_password.required" class="invalid-feedback d-block">
                                        Password Baru Wajib Diisi</div>
                                    </div>

                                    <div class="mt-3">
                                        <label>Ulangi Password Baru</label>
                                        <input type="password" autocomplete="off" class="form-control" aria-describedby="emailHelp" placeholder="Ulangi Password Baru..." v-model="form.retype_new_password"
                                        :class="{ 'is-invalid': submitted && $v.form.retype_new_password.$error }"
                                        >

                                        <div v-if="submitted && !$v.form.retype_new_password.required" class="invalid-feedback d-block">
                                        Ulangi Password Wajib Diisi</div>
                                    </div>

                                    <hr/>
                                    <div class="mt-3">
                                        <button :disabled="waiting" type="submit" class="btn btn-primary btn-block"><i class="fas fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan Perubahan' }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'AdminMember',
        metaInfo: {
            title: 'Data Users',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        validations: {
            form: {
                new_password: {
                    required
                },
                retype_new_password: {
                    required
                },
            }
        },
        data() {
            return {
                dataClass    : [],
                emptyState   : false,
                dataFetch   : false,
                dataList    : [],
                selectAll   : false,
                selectedIds : [],
                dataSchool  : [],
                keywords    : "",
                type        : "",
                school_id   : "",
                school_class : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                form : {
                    id                  : '',
                    new_password        : '',
                    retype_new_password : '',
                },
                submitted   : false,
                waiting     : false
            }
        },
        created() {
            this.getData();
            this.getSchool();
        },
        methods: {
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/members/school';
                this.$http.get(uri).then(res => {
                    this.dataSchool   = res.data.data;
                });
            },
            getClass: function() {
                this.dataSchool.forEach(element => {
                    if(element.id == this.school_id) {
                        this.dataClass = element.data_class
                    }
                });
            },
            getByFilter: function() {
                this.pagination.currentPage = 1;
                this.getData();
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/members/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords     : this.keywords,
                        type         : this.type,
                        school_id    : this.school_id,
                        school_class : this.school_class,
                        page         : this.pagination.currentPage,
                        limit        : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/members/change_password', {
                        id                  : this.form.id,
                        new_password        : this.form.new_password,
                        retype_new_password : this.form.retype_new_password,
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.waiting    = false;
                                    this.submitted  = false;
                                    document.getElementById("btnCloseModal").click();
                                    this.getData();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                            this.waiting = false;
                        }
                    })
                }
            },
            setId: function(id) {
                this.form.id = id;
            },
            nonActive: function (id, params='non-active') {
                let title = "Non Aktifkan Akun";
                let text = "Apakah kamu yakin ingin menon-aktifkan akun ini?";

                if(params == 'active') {
                    title = "Aktifkan Akun";
                    text = "Apakah kamu yakin ingin mengaktifkan akun ini?";
                }
                this.$swal({
                icon: 'info',
                title: title,
                text: text,
                confirmButtonText: 'Iya',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/member/non-active', {
                        id: id,
                        params : params
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
            selectAllItems() {
                if (this.selectAll) {
                    this.selectedIds = this.dataList.map(item => item.id);
                } else {
                    this.selectedIds = [];
                }
            },
            deleteData: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Hapus Data',
                    html: 'Apakah kamu yakin ingin <b>menghapus '+this.selectedIds.length+'</b> data ini?<br/>Semua transaksi pada akun ini akan dihapus dan tidak dapat dikembalikan',
                    confirmButtonText: 'Hapus',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/members/delete_batch', {
                        id: this.selectedIds
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });                
            }
        }
    }
</script>
