<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Pembiayaan</h5>
                        </div>
                        <div class="row" v-if="dataFetch">
                            <div class="col-lg-4" v-for="(item,index) in [1,2,3,4,5,6]" :key="index">
                                <div class="card d-block border rounded-lg p-3 m-t-10">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="mr-3 w-100">
                                            <div class="d-flex align-items-center">
                                                <div class="w-100">
                                                    <div class="skeleton-bar text-center">
                                                        <div class="sb-sm mb-2"></div>
                                                        <hr/>
                                                        <div class="sb-lg sb-thin mb-2"></div>
                                                        <div class="sb-lg sb-thin mb-4"></div>
                                                        <div class="sb-lg"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="!dataFetch">
                            <div class="col-lg-6" v-for="(item, index) in dataList" :key="index">
                                <div class="card card-theory-no-hover border">
                                    <img v-if="item.cover" class="img-fluid card-img-top" :src="item.cover" alt="cover">
                                    <img v-if="!item.cover" class="img-fluid card-img-top" src="assets/images/default-cover.png" alt="cover">
                                    <div class="card-body d-flex flex-column">
                                        <h5 class="card-title text-short">
                                            <a href="javascript:void(0)">{{item.title}}</a>
                                        </h5>

                                        <div v-for="(itemDetail, indexDetail) in item.data_tagihan" :key="indexDetail" class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                            <div class="mr-3">{{ itemDetail.nama_tagihan }}</div>
                                            <div class="font-weight-600 text-right">
                                                {{ itemDetail.text_jumlah_tagihan }}
                                                <br/>
                                                <span v-if="itemDetail.lunas == 0" class="badge badge-danger">Belum Lunas</span>
                                                <span v-if="itemDetail.lunas == 1" class="badge badge-success">Sudah Lunas</span>
                                            </div>
                                        </div>

                                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3">
                                            <div class="mr-3">Total Tagihan</div>
                                            <div class="font-weight-600">
                                                {{ item.text_total_tagihan }}
                                            </div>
                                        </div>

                                        <div v-if="item.daftar_ulang == 0" class="alert alert-warning text-center" role="alert">
                                            <b>Ayo Segera lunasi tagihan & lakukan daftar ulang untuk proses finalisasi/pengumuman hasil akhir.</b>
                                        </div>

                                            <div v-if="item.daftar_ulang == 1 && item.lulus == 0" class="alert alert-info" role="alert">
                                            Pembayaran & proses daftar ulang selesai!
                                            <br/>
                                            <b>Harap tunggu hasil pengumuman kamu akan segera tiba</b>
                                        </div>

                                        <div v-if="item.daftar_ulang == 1 && item.lulus == 1" class="alert alert-info" role="alert">
                                            <b>Selamat ya! Sekarang kamu telah resmi menjadi siswa disekolah ini.</b>
                                        </div>

                                        <div class="d-grid mb-0">
                                            <button :disabled="is_download" v-on:click="cetakLulus(item.ppdb_formulir_id)" v-if="item.lulus == 1" type="button" class="btn btn-outline-primary rounded btn-block mb-0">
                                                <i class="fa fa-download"></i> {{is_download ? 'Loading...':'Cetak Bukti Kelulusan'}}
                                            </button>
                                        </div>
                                        <p class="mt-0" v-if="item.lulus == 0">
                                            Status Daftar Ulang
                                            <br/>
                                            <span v-if="item.daftar_ulang == 1" class="badge badge-success">Sudah Daftar Ulang</span>
                                            <span v-if="item.daftar_ulang == 0" class="badge badge-dark">Belum Melakukan Daftar Ulang</span>
                                        </p>                                             
                                    </div>                        
                                </div>
                            </div>
                        </div>

                        <div>
                            <nav v-if="dataList.length > 0" aria-label="navigation" style="margin-top:-15px !important">
                                <hr/>
                                <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                            </nav>

                            <div v-if="emptyState">
                                <div class="card card-theory-no-hover border p-3">
                                    <empty-state></empty-state>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'FormulirPendaftaran',
        metaInfo: {
            title: 'Pembiayaan',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch       : false,
                dataList        : [],
                emptyState      : false,
                keywords        : "",
                is_download     : false,
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 9,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        components: {
            vPagination
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'ppdb/pembiayaan-list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            cetakLulus: function(id) {
                this.is_download = true;
                this.$http.get(process.env.VUE_APP_APIHOST + 'ppdb/pembiayaan/cetak-lulus', {
                    params : {
                        id : id,
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Kartu Bukti Lulus'}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }

                    this.is_download = false;
                })
            }
        }
    }
</script>
