<template>
    <div>
        <div v-if="dataFetch">
            <p>Sedang memuat data...</p>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Syarat dan Ketentuan</h5>
                        </div>
                    </div>
                    <div v-if="!dataFetch" class="col-md-12">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <ckeditor v-model="form.syarat_ppdb" :config="editorConfig"></ckeditor>
                                </div>
                            </div>
                            <button type="submit" class="btn rounded btn-primary m-r-10" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>

                            <router-link  :to="{ name: 'view_syarat_ketentuan'}" type="button" class="btn rounded btn-outline-primary m-r-10">
                                Preview Tampilan
                            </router-link >
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>    
    export default {
        name: 'SK',
        metaInfo: {
            title: 'Syarat dan Ketentuan - PPDB',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                form : {
                    id              : "",
                    syarat_ppdb     : "",
                },
                submitted   : false,
                waiting     : false,
                dataFetch   : true,
                editorConfig: {
                    toolbar: [
                        {
                            name: 'clipboard',
                            items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo']
                        },
                        {
                            name: 'editing',
                            items: ['Find', 'Replace', '-', 'SelectAll', 'Scayt']
                        },
                        {
                            name: 'basicstyles',
                            items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat']
                        },
                        {
                            name: 'paragraph',
                            items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv']
                        },
                        {
                            name: 'alignment',
                            items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
                        },
                        {
                            name: 'links',
                            items: ['Link', 'Unlink', 'Anchor']
                        },
                        {
                            name: 'insert',
                            items: ['Image', 'EmbedSemantic', 'Table', 'HorizontalRule', 'SpecialChar']
                        },
                        {
                            name: 'styles',
                            items: ['Styles', 'Format', 'Font', 'FontSize']
                        },
                        {
                            name: 'colors',
                            items: ['TextColor', 'BGColor']
                        },
                        {
                            name: 'tools',
                            items: ['Maximize', 'ShowBlocks', 'Source']
                        }
                    ],
                    extraPlugins: 'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget,justify,font,colorbutton,colordialog,find',
                    imageUploadUrl: process.env.VUE_APP_APIHOST + 'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST + 'uploader/files',
                    versionCheck: false
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                let formData = new FormData();

                formData.append('id', this.form.id);
                formData.append('syarat_ppdb', this.form.syarat_ppdb);

                this.$http.post(process.env.VUE_APP_APIHOST + 'admin/ppdb-formulir/save-sk', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
            },
            getData: function() {
                let uri     = process.env.VUE_APP_APIHOST+'admin/ppdb-formulir/check-school';
                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    if(res.data.status) {
                        this.dataFetch  = false;
                        let getResponse = res.data.data;
                        this.form       = getResponse;
                    }
                });
            }
        }
    }
</script>