<template>
    <div>
        <div v-if="dataFetch">
            <p>Sedang memuat data...</p>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Syarat dan Ketentuan</h5>
                        </div>
                    </div>
                    <div v-if="!dataFetch" class="col-md-12" v-html="form.syarat_ppdb"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>    
    export default {
        name: 'viewSK',
        metaInfo: {
            title: 'Syarat dan Ketentuan - PPDB',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                form : {
                    id              : "",
                    syarat_ppdb     : "",
                },
                submitted   : false,
                waiting     : false,
                dataFetch   : true
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri     = process.env.VUE_APP_APIHOST+'admin/ppdb-formulir/check-school';
                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    if(res.data.status) {
                        this.dataFetch  = false;
                        let getResponse = res.data.data;
                        this.form       = getResponse;
                    }
                });
            }
        }
    }
</script>