<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Hasil Pengumuman Kelulusan Peserta</h5>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch">
                                    <div v-if="dataList.length > 0" >
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th style="width:5%" class="text-center">No</th>
                                                        <th>Kelas / Peminatan</th>
                                                        <th style="width:10%" class="text-center">Total Peserta Lulus</th>
                                                        <th class="text-center" style="width:15%">Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="dataList.length > 0">
                                                    <tr v-for="(item, index) in dataList" :key="index">
                                                        <td class="text-center">
                                                            {{ index+1 }}
                                                        </td>
                                                        <td>{{ item.peminatan }}</td>
                                                        <td class="text-center">{{ item.total_users }} Peserta</td>
                                                        <td>
                                                            <a data-toggle="modal" data-target="#modal_hasil_pengumuman" href="javascript:void(0)" v-on:click="showUser(item)" class="btn btn-outline-primary btn-sm rounded m-r-10"><i class="fas fa-users"></i> Lihat Detail Peserta</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-if="dataList.length < 1">
                                                    <tr colspan="9">
                                                        <td>Data tidak ditemukan</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<!-- Modal -->
    <div class="modal" id="modal_hasil_pengumuman" tabindex="-1" aria-hidden="true" data-backdrop="static">
        <div class="modal-lg modal-dialog modal-dialog-centered">
            <div class="modal-content" style="overflow: hidden;">
                <div class="d-block px-3 pt-3 pb-0">
                    <div class="d-flex align-items-center justify-content-between text-sm">
                        <div class="font-weight-600" style="font-size:20px"><i class="fa fa-users"></i> Daftar Peserta</div>
                        <div>
                            <button id="btnCloseModalStatus" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                data-dismiss="modal">Tutup</button>
                        </div>
                    </div>
                </div>

                <div class="d-block px-3 pt-3 pb-0">
                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                        <div class="mr-3">Peminatan</div>
                        <div class="font-weight-600">{{ data_modal.peminatan }}</div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                        <div class="mr-3">Total Peserta Lulus</div>
                        <div class="font-weight-600">{{ data_modal.total_users }} Orang</div>
                    </div>

                    <input type="text" class="form-control" placeholder="Cari data berdasarkan nama peserta atau no pendaftaran..."/>
                    <div class="table-responsive mt-3">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <td style="width: 5%;" class="text-center">No</td>
                                    <td>Nama Peserta</td>
                                    <td style="width: 5%;" class="text-center">No Pendaftaran</td>
                                    <td style="width: 5%;" class="text-center">Aksi</td>
                                </tr>
                            </thead>
                            <tbody v-if="data_modal.detail_users.length > 0">
                                <tr v-for="(itemModal, indexModal) in data_modal.detail_users" :key="indexModal">
                                    <td class="text-center">{{ ++indexModal }}</td>
                                    <td>{{ itemModal.fullname }}</td>
                                    <td>{{ itemModal.no_pendaftaran }}</td>
                                    <td>
                                        <button :disabled="is_download" v-on:click="downloadHasilFormulir(itemModal.users_id, itemModal.fullname, itemModal.no_pendaftaran, itemModal.ppdb_formulir_id)" type="button" class="btn d-flex justify-content-between align-items-center rounded btn-sm btn-primary btn-block">
                                            <span>{{ is_download ? 'Loading...':'Download Hasil Formulir' }}</span>
                                            <span class="ml-auto">🖨️</span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-if="data_modal.detail_users.length < 1">
                                <tr>
                                    <td colspan="4">Data tidak tersedia</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>          
        </div>          
    </div>          
</div>
</template>

<script>
    export default {
        name: 'HasilPengumuman',
        metaInfo: {
            title: 'Data Hasil Pengumuman Kelulusan',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                is_download : false,
                emptyState  : false,
                dataFetch   : false,
                dataList    : [],
                keywords    : "",
                data_modal  : {
                    detail_users : []
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/ppdb/hasil-pengumuman';

                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            showUser: function(item) {
                this.data_modal = item;
            },
            downloadHasilFormulir: function(users_id="", fullname="", no_pendaftaran="", id="") {
                this.is_download = true;
                let title = fullname+" - "+no_pendaftaran;
                this.$http.get(process.env.VUE_APP_APIHOST + 'admin/ppdb/formulir/cetak-hasil', {
                    params : {
                        id : id,
                        users_id : users_id
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    this.is_download = false;
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Hasil Formulir Pendaftaran - '+title}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
            },

        }
    }
</script>
