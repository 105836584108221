<template>
    <div class="card-body py-5 text-center">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    mounted() {
        let uri         = process.env.VUE_APP_APIHOST+'user/school';
        this.dataFetch  = true;                
        this.$http.get(uri).then(res => {
            if(res.data.status) {
                let url_ppdb    = res.data.data.url_ppdb;
                let url_login   = location.origin + location.pathname + '#/ppdb/' + url_ppdb;
                localStorage.removeItem('auth_token');
                axios.defaults.headers.common['Authorization'] = '';        
                location.href = url_login
            } else {
                localStorage.removeItem('auth_token');
                axios.defaults.headers.common['Authorization'] = '';        
                location.href = location.origin + location.pathname + location.search
            }
        });
    }
}
</script>