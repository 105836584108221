<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Data Komponen Pembiayaan</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <a href="javascript:void(0)" class="btn btn-primary rounded m-r-10" data-toggle="modal" data-target="#modal_form_komponen_tagihan" v-on:click="addData()">Tambah Data</a>
                                <router-link :to="{name : 'admin_ppdb_formulir'}" class="btn btn-outline-primary rounded"><i class="fa fa-back"></i> Kembali</router-link>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getByKeywords">
                                </div>

                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive m-t-20">
                                    <div v-if="dataList.length > 0" >
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <th style="width:60%">Nama Komponen</th>
                                                    <th style="width:20%">Jumlah Tagihan</th>
                                                    <th style="width:5%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>{{item.nama_tagihan}}</td>
                                                    <td>{{ item.text_jumlah_tagihan }}</td>
                                                    <td>
                                                        <a href="javascript:void(0)" class="btn btn-primary btn-sm rounded m-r-10" data-toggle="modal" data-target="#modal_form_komponen_tagihan" v-on:click="editData(item)"><i class="fas fa-edit"></i> Ubah</a>
                                                        <button v-on:click="deleteData(item.id)" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Hapus" type="button" class="btn btn-danger btn-sm rounded "><i class="fas fa-trash"></i> Hapus</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <nav aria-label="navigation" class="m-t-20">
                                            <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                        </nav>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="modal_form_komponen_tagihan" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-lg modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px">{{form.id ? 'Ubah':'Tambah'}} Komponen Tagihan</div>
                            <div>
                                <button type="button" id="closeModal" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    <div class="d-block p-3 m-t-10">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Nama Komponen</label>
                                <div class="col-sm-9">
                                    <input type="hidden" class="form-control" id="" placeholder="Masukan nama Komponen Tagihan..." v-model="form.id" autocomplete="off">
                                    <input type="text" class="form-control" id="inputEmail3" placeholder="Masukan kode Komponen Tagihan..." v-model="form.nama_tagihan" autocomplete="off">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Jumlah Tagihan</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" placeholder="Masukan nama Komponen Tagihan..." v-model="form.jumlah_tagihan" autocomplete="off">
                                </div>
                            </div>

                            <button type="submit" class="btn btn-primary rounded btn-block" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                         </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'BillComponent',
        metaInfo: {
            title: 'Data Komponen Tagihan',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                waiting         : false,
                emptyState      : false,
                dataFetch       : false,
                dataList        : [],
                keywords        : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                form : {
                    id : '',
                    nama_tagihan    : '',
                    jumlah_tagihan  : ''
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            addData: function() {
                this.form.id = '';
                this.form.nama_tagihan = '';
                this.form.jumlah_tagihan = '';
            },
            getByKeywords: function() {
                this.pagination.currentPage = 1;
                this.getData();
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/ppdb-formulir/komponen-biaya-list';
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        ppdb_formulir_id : this.$route.params.id,
                        keywords  : this.keywords,
                        page      : this.pagination.currentPage,
                        limit     : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            deleteData: function (id) {
                this.$swal({
                icon: 'info',
                title: 'Hapus Data',
                text: 'Apakah kamu yakin ingin menghapus data ini?',
                confirmButtonText: 'Hapus',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/ppdb-formulir/komponen-biaya-delete', {
                        id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
            editData: function(item) {
                this.form.id = item.id;
                this.form.nama_tagihan = item.nama_tagihan;
                this.form.jumlah_tagihan = item.jumlah_tagihan
            },
            onSubmit: function() {
                if(this.form.nama_tagihan == '' || this.form.jumlah_tagihan == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Harap lengkapi form!",
                    });

                } else {
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('ppdb_formulir_id', this.$route.params.id);
                    formData.append('nama_tagihan', this.form.nama_tagihan);
                    formData.append('jumlah_tagihan', this.form.jumlah_tagihan);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/ppdb-formulir/komponen-biaya-save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    document.getElementById("closeModal").click();
                                    this.getData();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                html: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });
                }
            }
        }
    }
</script>
