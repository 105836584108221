<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><a v-on:click="goBack()" href="javascript:void(0)"><i class="fa fa-arrow-left m-r-10"></i></a>
                                Data Sekolah Asal
                            </h5>
                        </div>
                    </div>
                </div>

                <form v-on:submit.prevent="onSubmit">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="d-block mb-1 mt-2">
                                <div class="row">
                                    <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="hidden" name="id" v-model="form.id">

                                    <div class="form-group col-md-6">
                                        <label for="npsn">NPSN <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="npsn"
                                            placeholder="Masukan data..." v-model="form.npsn"
                                            :class="{ 'is-invalid': submitted && $v.form.npsn.$error }">
                                        <div v-if="submitted && !$v.form.npsn.required" class="invalid-feedback">NPSN wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="sekolah_dasar">Asal Sekolah Dasar (SD) <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="sekolah_dasar"
                                            placeholder="Masukan data..." v-model="form.sekolah_dasar"
                                            :class="{ 'is-invalid': submitted && $v.form.sekolah_dasar.$error }">
                                        <div v-if="submitted && !$v.form.sekolah_dasar.required" class="invalid-feedback">Asal Sekolah Dasar (SD) wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="sltp">Asal Sekolah Menengah Pertama (SMP) <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="sltp"
                                            placeholder="Masukan data..." v-model="form.sltp"
                                            :class="{ 'is-invalid': submitted && $v.form.sltp.$error }">
                                        <div v-if="submitted && !$v.form.sltp.required" class="invalid-feedback">Asal Sekolah Menengah Pertama (SMP) wajib diisi!</div>
                                    </div>

                                    <!--div class="form-group col-md-6">
                                        <label for="sltp">Foto Kartu Keluarga (KK) <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="foto_kk"
                                            v-model="form.foto_kk"
                                            placeholder="Silahkan upload foto kartu keluarga (KK)" style="cursor:pointer"
                                            :class="{ 'is-invalid': submitted && $v.form.foto_kk.$error }"
                                            readonly
                                            v-on:click="viewData(form.foto_kk)"
                                            >
                                        <div v-if="submitted && !$v.form.foto_kk.required" class="invalid-feedback">File ini wajib diisi!</div>

                                        <input class="d-none" type="file" name="file_kk" id="file_kk" @change="previewFiles_kk" accept="image/x-png,image/gif,image/jpeg">
                                        <div class="input-group-append mt-2" v-if="dataDetail.member.terkirim == 0">
                                            <button v-on:click="chooseUploadKK()" :disabled="upload_foto_kk" class="btn btn-sm btn-primary rounded" type="button">
                                                <i class="fa fa-upload"></i> {{ upload_foto_kk ? 'Uploading...' : 'Pilih File' }}
                                            </button>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="sltp">Foto siswa <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="foto_siswa"
                                            v-model="form.foto_siswa"
                                            placeholder="Silahkan upload foto siswa" style="cursor:pointer"
                                            :class="{ 'is-invalid': submitted && $v.form.foto_siswa.$error }"
                                            readonly
                                            v-on:click="viewData(form.foto_siswa)"
                                            >
                                        <div v-if="submitted && !$v.form.foto_siswa.required" class="invalid-feedback">File ini wajib diisi!</div>

                                        <input class="d-none" type="file" name="file_siswa" id="file_siswa" @change="previewFiles_siswa" accept="image/x-png,image/gif,image/jpeg">
                                        <div class="input-group-append mt-2" v-if="dataDetail.member.terkirim == 0">
                                            <button v-on:click="chooseUploadSiswa()" :disabled="upload_foto_siswa" class="btn btn-sm btn-primary rounded" type="button">
                                                <i class="fa fa-upload"></i> {{ upload_foto_siswa ? 'Uploading...' : 'Pilih File' }}
                                            </button>
                                        </div>
                                    </div-->
                                </div>
                            </div>

                            <small>Keterangan: Tanda (<span class="text-danger">*</span>) Wajib Diisi</small>
                        </div>

                        <div class="col-md-4 text-center">
                            <div v-if="dataDetail.member.terkirim == 1" class="alert alert-info text-center" role="alert">
                                <b>Keterangan:</b><br/> Kamu tidak bisa melakukan perubahan ketika data sudah terkirim/sedang dalam proses verifikasi.
                            </div>

                            <button
                                :disabled="dataDetail.member.terkirim == 1 ? true:false"
                                type="submit"
                                class="d-flex justify-content-between align-items-center btn btn-primary rounded btn-block m-t-10">
                                <span><i class="fa fa-save"></i> Simpan Data</span>
                                <span class="ml-auto">💾</span>
                            </button>
                            <button
                                v-on:click="alertBack()"
                                type="button"
                                class="d-flex justify-content-between align-items-center btn btn-outline-primary rounded btn-block m-t-10">
                                <span>Kembali</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'AsalSekolah',
        metaInfo: {
            title: 'Data Sekolah Asal',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch   : false,
                dataDetail  : [],
                form        : {},
                submitted   : false,
                waiting     : false,
                preview_foto_kk : '',
                preview_foto_siswa : '',
                upload_foto_kk : false,
                upload_foto_siswa : false
            }
        },
        created() {
            this.getData()
        },
        validations: {
            form: {
                npsn: {
                    required
                },
                sekolah_dasar: {
                    required
                },
                sltp: {
                    required
                }
                // },
                // foto_kk: {
                //     required
                // },
                // foto_siswa: {
                //     required
                // },
            }
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'ppdb/formulir-detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id        : this.$route.params.ppdb_formulir_id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataDetail = res.data.data
                        this.getIdentitas();
                    } else {
                        this.dataFetch  = false;
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'member_ppdb_formulir' });
                            }
                        });
                    }
                });
            },
            getIdentitas: function() {
                let uri         = process.env.VUE_APP_APIHOST+'ppdb/sekolah-asal';
                this.$http.get(uri).then(res => {
                    this.dataFetch  = false;         
                    if(res.data.status) {
                        this.form = res.data.data
                    }
                });
            },
            goBack: function() {
                this.$router.push({
                    name: 'member_ppdb_formulir_detail',
                    params : {
                        id : this.$route.params.ppdb_formulir_id
                    }
                });
            },
            alertBack: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Informasi',
                    html: 'Apakah kamu yakin ingin kembali ke halaman sebelumnya?',
                    confirmButtonText: 'Iya, Kembali',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.goBack()
                    }
                });                
            },
            onSubmit: function () {
                this.waiting = true;
                this.submitted = true;

                // Validasi form
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                }

                let formData = new FormData();

                formData.append('id', this.form.id);
                formData.append('npsn', this.form.npsn);
                formData.append('sekolah_dasar', this.form.sekolah_dasar);
                formData.append('sltp', this.form.sltp);
                // formData.append('foto_kk', this.form.foto_kk);
                // formData.append('foto_siswa', this.form.foto_siswa);
                formData.append('ppdb_formulir_id',  this.$route.params.ppdb_formulir_id);

                this.$http.post(process.env.VUE_APP_APIHOST + 'ppdb/sekolah-asal/save', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.goBack();
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                    this.waiting = false;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Silakan cek koneksi internet Anda.',
                    });
                    this.waiting = false;
                });
            },
            viewData: function(url="") {
                if(url) {
                    window.open(url, '_blank');                     
                }
            },
            chooseUploadKK: function () {
                document.getElementById("file_kk").click()
            },
            previewFiles_kk(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.upload_foto_kk      = true;
                    
                    let formData    = new FormData();
                    formData.append('file_attachment', file);
                    formData.append('params', 'foto_kk');

                    this.$http.post(process.env.VUE_APP_APIHOST+'ppdb/sekolah-asal/save-upload', formData )
                    .then(response => {
                        this.upload_foto_kk = false;

                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getIdentitas();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    });                    
                }
            },
            chooseUploadSiswa: function () {
                document.getElementById("file_siswa").click()
            },
            previewFiles_siswa(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.upload_foto_siswa      = true;
                    
                    let formData    = new FormData();
                    formData.append('file_attachment', file);
                    formData.append('params', 'foto_siswa');

                    this.$http.post(process.env.VUE_APP_APIHOST+'ppdb/sekolah-asal/save-upload', formData )
                    .then(response => {
                        this.upload_foto_siswa = false;

                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getIdentitas();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    });                    
                }
            }
        }
    }
</script>