<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">{{label}} Users</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'users'}">Users</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Form</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header">
            <div class="page-block">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <input type="hidden" name="id" v-model="form.id">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Username / Email</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.username" :class="{ 'is-invalid': submitted && $v.form.username.$error }">
                                    <div v-if="submitted && !$v.form.username.required" class="invalid-feedback">Username wajib diisi!</div>    
                                    <small><i>Username / Email tidak boleh sama dengan pengguna lain.</i></small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Password</label>
                                    <input type="password" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.password" :class="{ 'is-invalid': submitted && $v.form.password.$error }" :disabled="form.id != ''">
                                    <div v-if="submitted && !$v.form.password.required" class="invalid-feedback">Password wajib diisi!</div>
                                    <small v-if="form.id != ''"><i>Password tidak dapat diubah.</i></small>                                    
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Nama Lengkap</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.fullname" :class="{ 'is-invalid': submitted && $v.form.fullname.$error }">
                                    <div v-if="submitted && !$v.form.fullname.required" class="invalid-feedback">Nama Lengkap wajib diisi!</div>    
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Role</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.type" :class="{ 'is-invalid': submitted && $v.form.type.$error }">
                                        <option value="">Pilih Role</option>
                                        <option value="member">Member</option>
                                        <option value="proctor">Proktor</option>
                                        <option value="content">Guru</option>
                                        <option value="headmaster">Kepala Sekolah</option>
                                        <option value="ppdb_admin">Admin PPDB (Panitia)</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.type.required" class="invalid-feedback">Role wajib dipilih!</div>    
                                </div>
                                <div class="form-group col-md-6" v-if="form.type == 'proctor' || form.type == 'content' || form.type == 'member' || form.type == 'headmaster' || form.type == 'ppdb_admin'" v-on:change="getClass()">
                                    <label for="exampleFormControlSelect1">Sekolah</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.school_id">
                                        <option value="">Pilih Sekolah</option>
                                        <option v-for="(item, index) in dataSchool" :key="index" :value="item.id">{{item.name}}</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-6" v-if="form.type == 'member'">
                                    <label for="exampleFormControlSelect1">Sekolah</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.school_class">
                                        <option value="">Pilih Kelas</option>
                                        <option v-for="(item, index) in dataClass" :key="index" :value="item">{{item}}</option>
                                    </select>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary m-r-10" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                            <router-link :to="{name : 'users'}" class="btn btn-outline-primary"><i class="fa fa-back"></i> Kembali</router-link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'Users',
        metaInfo: {
            title: 'Form Users',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.getSchool();
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label   : 'Tambah',
                form : {
                    id              : "",
                    username        : "",
                    password        : "",
                    fullname        : "",
                    type            : "",
                    school_id       : "",
                    school_class    : ""
                },
                dataSchool      : [],
                dataClass       : [],
                submitted       : false,
                waiting         : false
            }
        },
        validations: {
            form: {
                username: {
                    required
                },
                password: {
                    required
                },
                fullname: {
                    required
                },
                type: {
                    required
                }
            }
        },
        methods: {
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/members/school';
                this.$http.get(uri).then(res => {
                    this.dataSchool   = res.data.data;
                });
            },
            getClass: function() {
                this.dataSchool.forEach(element => {
                    if(element.id == this.form.school_id) {
                        this.dataClass = element.data_class
                    }
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);

                    if(this.form.id == '') {
                        formData.append('password', this.form.password);
                    }

                    formData.append('username', this.form.username);
                    formData.append('fullname', this.form.fullname);
                    formData.append('type', this.form.type);
                    formData.append('school_id', this.form.school_id);
                    formData.append('school_class', this.form.school_class);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/members/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'users' });
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/members/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;

                            this.form.id        = getResponse.id;
                            this.form.username  = getResponse.username;
                            this.form.password  = "***********************";
                            this.form.fullname  = getResponse.fullname;
                            this.form.type      = getResponse.type;
                            this.form.school_id = getResponse.school_id;

                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'users' });
                                }
                            });
                        }
                    });
                }
            }
        }
    }
</script>