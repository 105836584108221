<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><router-link :to="{name : 'member_ppdb_formulir'}"><i class="fa fa-arrow-left m-r-10"></i></router-link>
                                Formulir Pendaftaran
                            </h5>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8">
                        <div class="d-block mb-1 mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Nama Formulir</div>
                                <div class="font-weight-600" style="text-transform:uppercase;">{{dataDetail.title}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Tahun Ajaran</div>
                                <div class="font-weight-600" style="text-transform:uppercase;">{{dataDetail.tahun_ajaran}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Status Formulir</div>
                                <div class="font-weight-600" style="text-transform:uppercase;">
                                    <span v-if="dataDetail.is_opened == 1" class="badge badge-success">Pendaftaran Dibuka</span>
                                    <span v-if="dataDetail.is_opened == 0" class="badge badge-danger">Pendaftaran Ditutup</span>
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2">
                                <div class="mr-3" style="vertical-align: top !important;"> 
                                    Data yang harus kamu lengkapi<br/>
                                    <ol style="margin-top:10px !important">
                                        <li v-for="(item, index) in dataDetail.step_registrasi" :key="index">{{ item.name }} 
                                            <span v-if="item.is_done == 1"><b>✔️ (Sudah Lengkap)</b></span>
                                            <span v-if="item.is_done == 0"><b>❌ (Belum Lengkap)</b></span>
                                        </li>
                                    </ol>
                                </div>
                            </div>

                            <div v-if="dataDetail.member.terkirim == 0" class="d-flex align-items-center justify-content-between pb-2">
                                <div class="mr-3" style="vertical-align: top !important;"> 
                                    <p v-if="dataDetail.member.status_pendaftaran == 'gagal_verifikasi'" class="alert alert-danger" role="alert">
                                        Maaf, verifikasi data formulir kamu <b>ditolak</b>. Pastikan semua data dan dokumen yang diunggah sesuai dengan persyaratan yang telah ditentukan. Silakan periksa kembali berkas Kamu dan unggah ulang sebelum batas waktu yang ditentukan.
                                        <br/>
                                        <br/>
                                        <span v-if="dataDetail.member.pesan_gagal">
                                            <b>Alasan Ditolak:</b><br/>
                                            {{ dataDetail.member.pesan_gagal }}
                                        </span>
                                        <span v-if="dataDetail.data_step_gagal">
                                            <br/>
                                            <br/>
                                            <b>Berikut data yang harus kamu perbaiki:</b><br/>
                                            <ul>
                                                <li v-for="(itemDetail, indexDetail) in dataDetail.data_step_gagal" :key="indexDetail">{{ itemDetail.name }} ❌</li> 
                                            </ul>
                                        </span>
                                    </p>
                                </div>
                            </div>

                            <div v-if="dataDetail.member.terkirim == 1 && dataDetail.member.status_pendaftaran == 'lolos_verifikasi'" class="d-flex align-items-center justify-content-between pb-2">
                                <div class="mr-3" style="vertical-align: top !important;"> 
                                    <p class="alert alert-success" role="alert">
                                        <b>Selamat! Verifikasi Berkas kamu Lolos dalam tahap verifikasi.</b>
                                        <br/>
                                        <br/>
                                        Kami dengan senang hati menginformasikan bahwa berkas Kamu telah berhasil melewati tahap verifikasi. Kamu kini telah melanjutkan ke tahap selanjutnya. Pastikan Kamu mempersiapkan diri dengan baik untuk proses berikutnya. <br/>
                                        <br/>Jika ada informasi atau langkah yang perlu Kamu ikuti, akan kami sampaikan segera. Terima kasih telah mengikuti proses ini dengan baik. Semoga sukses!
                                    </p>
                                </div>
                            </div>
                           
                         </div>
                    </div>

                    <div class="col-md-4 text-center">
                        <div class="card card-theory">
                            <div class="card-body">
                                <h5 class="job-card-desc text-center">
                                    Status Pendaftaran
                                </h5>

                                <div class="text-center m-t-10">
                                    <h5 v-if="dataDetail.member.status_pendaftaran == 'belum_melengkapi'" class="alert alert-danger text-center" role="alert">
                                        Belum Melengkapi Data
                                    </h5>

                                    <h6 v-if="dataDetail.member.status_pendaftaran == 'menunggu_verifikasi'" class="alert alert-warning text-center" role="alert">
                                        Menunggu Tahap<br/>Verifikasi Data
                                    </h6>

                                    <h6 v-if="dataDetail.member.status_pendaftaran == 'lolos_verifikasi'" class="alert alert-info text-center" role="alert">
                                        Lolos Tahap Verifikasi ✔️
                                    </h6>
                                    
                                    <h6 v-if="dataDetail.member.status_pendaftaran == 'gagal_verifikasi'" class="alert alert-danger text-center" role="alert">
                                        Verifikasi Gagal (Ditolak) ❌
                                    </h6>
                                </div>
                            </div>
                        </div>
                        
                        <button v-for="(item, index) in dataDetail.step_registrasi" :key="index"
                            v-on:click="goToPage(item.code, item.id)"
                            type="button"
                            :class="item.is_done == 0 ? 'btn-outline-primary':'btn-info'"
                            class="d-flex justify-content-between align-items-center btn rounded btn-block m-t-10">
                            <span><i :class="item.icon"></i> {{ item.name }}</span>
                            <span v-if="item.is_done == 0" class="ml-auto">❌</span>
                            <span v-if="item.is_done == 1" class="ml-auto">✔️</span>
                        </button>

                        <div v-if="(dataDetail.is_finished == 1 && (dataDetail.member.status_pendaftaran == 'belum_melengkapi' || dataDetail.member.status_pendaftaran == 'gagal_verifikasi'))">
                            <div class="alert alert-info text-center mt-2" role="alert">
                                Selamat ya!<br/>Kamu telah berhasil melengkapi semua data diatas.
                                Silahkan klik tombol dibawah ini untuk mengirim kelengkapan data kepada panitia.
                            </div>
                            <hr/>
                            <button
                                v-if="(dataDetail.is_finished == 1)"
                                v-on:click="sendData()"
                                type="button"
                                class="d-flex justify-content-between align-items-center btn btn-primary rounded btn-block m-t-10">
                                <span v-if="dataDetail.member.status_pendaftaran == 'belum_melengkapi'">Kirimkan Data dan Cetak Formulir</span>
                                <span v-if="dataDetail.member.status_pendaftaran == 'gagal_verifikasi'">Kirim ulang data dan Cetak Formulir</span>
                                <i class="ml-auto fa fa-paper-plane"></i>
                            </button>
                        </div>

                        <div v-if="(dataDetail.is_finished == 1 && dataDetail.member.status_pendaftaran == 'menunggu_verifikasi')">
                            <div class="alert alert-success text-center mt-2" role="alert">
                                Selamat ya!<br/>Kamu telah berhasil mengirimkan data kepada panitia.
                                Silahkan klik tombol dibawah ini untuk mencetak formulir pendaftaran.
                            </div>
                            <hr/>
                            <button
                                v-if="(dataDetail.is_finished == 1 && dataDetail.member.status_pendaftaran == 'menunggu_verifikasi')"
                                v-on:click="cetakFormulir()"
                                type="button"
                                class="d-flex justify-content-between align-items-center btn btn-outline-primary rounded btn-block m-t-10">
                                <span>Cetak Bukti Pendaftaran</span>
                                <span class="ml-auto">🖨️</span>
                            </button>
                        </div>

                        <div v-if="(dataDetail.is_finished == 1 && dataDetail.member.status_pendaftaran == 'lolos_verifikasi')">
                            <hr/>
                            <button
                                v-if="(dataDetail.is_finished == 1 && dataDetail.member.status_pendaftaran == 'lolos_verifikasi')"
                                v-on:click="cetakBuktiVerifikasi()"
                                type="button"
                                class="d-flex justify-content-between align-items-center btn btn-primary rounded btn-block m-t-10">
                                <span>Cetak Bukti Lolos Tahap Verifikasi</span>
                                <span class="ml-auto">🖨️</span>
                            </button>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FormulirDetail',
        metaInfo: {
            title: 'Detail Formulir Pendaftaran',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch  : false,
                dataDetail : []
            }
        },
        created() {
            this.getData()
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'ppdb/formulir-detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id        : this.$route.params.id
                    }
                }).then(res => {
                    this.dataFetch  = false;         
                    if(res.data.status) {
                        this.dataDetail   = res.data.data;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'member_ppdb_formulir' });
                            }
                        });
                    }
                });
            },
            goToPage: function(code, id) {
                if(code == 'identitas_siswa') {
                    this.$router.push({
                        name: 'member_ppdb_formulir_identitas_siswa',
                        params : {
                            ppdb_formulir_id : this.$route.params.id,
                            step_id: id
                        }
                    });
                }

                if(code == 'orangtua_wali') {
                    this.$router.push({
                        name: 'member_ppdb_formulir_orangtua_wali',
                        params : {
                            ppdb_formulir_id : this.$route.params.id,
                            step_id: id
                        }
                    });
                }

                if(code == 'asal_sekolah') {
                    this.$router.push({
                        name: 'member_ppdb_formulir_asal_sekolah',
                        params : {
                            ppdb_formulir_id : this.$route.params.id,
                            step_id: id
                        }
                    });
                }

                if(code == 'peminatan_rapor') {
                    this.$router.push({
                        name: 'member_ppdb_formulir_peminatan_rapor',
                        params : {
                            ppdb_formulir_id : this.$route.params.id,
                            step_id: id
                        }
                    });
                }            

                if(code == 'berkas') {
                    this.$router.push({
                        name: 'member_ppdb_formulir_berkas_siswa',
                        params : {
                            ppdb_formulir_id : this.$route.params.id,
                            step_id: id
                        }
                    });
                }
            },
            sendData: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Konfirmasi',
                    html: 'Apakah kamu yakin ingin mengirimkan semua data dan berkas?<br/>Pastikan data yang kamu kirim sudah sesuai.',
                    confirmButtonText: 'Iya, Kirim',
                    showCancelButton: true,
                    cancelButtonText: 'Cek Kembali',
                }).then((result) => {
                    if (result.value) {
                        let formData = new FormData();

                        formData.append('id', this.dataDetail.id);

                        this.$http.post(process.env.VUE_APP_APIHOST + 'ppdb/formulir-send/save', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.cetakFormulir();
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                        .catch(() => {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: 'Silakan cek koneksi internet Kamu.',
                            });
                            this.waiting = false;
                        });
                    }
                });                
            },
            cetakFormulir: function() {
                let title = this.dataDetail.title;
                this.$http.get(process.env.VUE_APP_APIHOST + 'ppdb/formulir-send/cetak', {
                    params : {
                        id : this.dataDetail.id,
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    this.isFetchCertificate = false;
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Kartu Bukti Pendaftaraan - '+title}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
            },
            cetakBuktiVerifikasi: function() {
                let title = this.dataDetail.title;
                this.$http.get(process.env.VUE_APP_APIHOST + 'ppdb/formulir/cetak-verifikasi', {
                    params : {
                        id : this.dataDetail.id,
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    this.isFetchCertificate = false;
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Kartu Bukti Lolos Verifikasi - '+title}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
            }
        }
    }
</script>